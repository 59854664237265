export const ShipmentLabelTextHelper = {
    getLabelText: {
        shipmentNumber: 'Shipment Number',
        shipmentReference: 'Shipment Reference',
        shipmentStatus: 'Shipment Status',
        shipmentName: 'Name',
        shipmentPhone: 'Phone',
        shipmentEmail: 'Email',
        shipmentAddress1: 'Address 1',
        shipmentAddress2: 'Address 2',
        shipmentAddress3: 'Address 3',
        shipmentPostalcode: 'Postal Code',
        shipmentCity: 'City',
        shipmentState: 'State',
        shipmentCountry: 'Country',
        shipmentCourierCode: 'Courier',
        trackingNumber: 'Tracking Number',
        remarks: 'Remarks',
        customerReference: 'Customer Reference',
        dateCreateOn: 'Create date',
        dateModifyOn: 'Modify date',
        dateCreateOnFrom: 'Create Date From',
        dateCreateOnTo: 'Create Date To',
        action: 'Action'
    }
};
