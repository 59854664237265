<!-- ExportToCSV.vue -->
<template>
    <v-btn @click="exportToCSV">Export to CSV</v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Papa from 'papaparse';

interface CsvHeader {
    key: string;
    text: string;
}

type FilenameProp = string | (() => string);
type Formatter = (value: unknown, key: string, row: Record<string, unknown>) => unknown;

export default defineComponent({
    name: 'ExportToCsv',
    props: {
        csvHeader: {
            type: Array as () => CsvHeader[],
            required: true
        },
        data: {
            type: Array as () => Record<string, unknown>[],
            required: true
        },
        filename: {
            type: ([String, Function] as unknown) as () => FilenameProp,
            default: 'data.csv'
        },
        formatter: {
            type: (Function as unknown) as () => Formatter,
            default: (value: unknown) => value
        }
    },
    methods: {
        exportToCSV() {
            const csv = Papa.unparse({
                fields: this.csvHeader.map((header) => header.text),
                data: this.data.map((row) =>
                    this.csvHeader.map((header) => this.formatter(row[header.key], header.key, row))
                )
            });

            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            const filename = typeof this.filename === 'function' ? this.filename() : this.filename;
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
});
</script>
