<template>
    <v-row justify="center" align="center">
        <v-col cols="12" sm="12" md="3"> </v-col>
        <v-col cols="12" sm="12" md="6" v-show="data.forgotPasswordStep === 'forgotPassword'">
            <h1>Forgot Password</h1>
            <v-card>
                <v-card-title> </v-card-title>
                <v-card-text>
                    <v-form ref="inputForm" v-model="data.isValidSignUp">
                        <v-text-field
                            ref="username"
                            v-model="resetPasswordRequest.username"
                            :label="formLabelText.username"
                            :rules="emailRules"
                            :disabled="loading"
                        ></v-text-field>
                        <v-btn
                            color="primary"
                            :loading="loading"
                            :disabled="loading || !data.isValidSignUp"
                            @click="onNextClick"
                        >
                            Next
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="6" v-show="data.forgotPasswordStep === 'resetPassword'">
            <h1>Reset Password</h1>
            <v-card>
                <v-card-title> </v-card-title>
                <v-card-text>
                    <v-form ref="inputFormResetPassword" v-model="data.isValidResetPassword">
                        <v-text-field
                            v-model="resetPasswordRequest.resetPasswordCode"
                            :label="formLabelText.resetPasswordCode"
                            :rules="resetPasswordCodeRules"
                            :disabled="loading"
                        ></v-text-field>
                        <v-text-field
                            v-model="resetPasswordRequest.newPassword"
                            :label="formLabelText.newPassword"
                            type="password"
                            :rules="passwordRules"
                            :disabled="loading"
                        ></v-text-field>
                        <v-btn
                            color="primary"
                            :loading="loading"
                            :disabled="loading || !data.isValidResetPassword"
                            @click="onResetClick"
                        >
                            Reset
                        </v-btn>
                    </v-form>
                </v-card-text>
            </v-card>
            <v-sheet class="mt-2 pt-3" width="500" max-width="500">
                <alert-message ref="errorAlert" :message="data.errorAlertMessage"></alert-message>
            </v-sheet>
        </v-col>
        <v-col cols="12" sm="12" md="3"> </v-col>
        <v-progress-circular v-if="loading" indeterminate size="70"></v-progress-circular>
        <v-overlay v-model="loading"> </v-overlay>
    </v-row>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { useAuthStore } from '../stores/AuthStore';
import _ from 'lodash';
import { LocalStorageHelper } from '../utils/LocalStorageHelper';
import { ValidationHelper } from '../utils/ValidationHelper';
import { AuthLabelTextHelper } from '../utils/labelTextHelper/AuthLabelTextHelper';
import { PathDefinition } from '../utils/PathDefinition';
import { Formatter } from '../utils/Formatter';
import AlertMessage from '../components/AlertMessage.vue';

export default defineComponent({
    name: 'ForgotPassword',

    components: {
        AlertMessage
    },

    setup() {
        const authStore = useAuthStore();
        const data = reactive({
            errorAlertMessage: '',
            isValidSignUp: false,
            isValidResetPassword: false,
            forgotPasswordStep: 'forgotPassword'
        });
        return {
            authStore,
            data
        };
    },
    data() {
        return {
            loading: false,
            emailRules: [ValidationHelper.getValidEmailRule(), ValidationHelper.getNotAllowWhitespaceRule()],
            passwordRules: [ValidationHelper.getRequiredRule(), ValidationHelper.getValidPasswordRule()],
            resetPasswordCodeRules: [ValidationHelper.getRequiredRule()],
            formLabelText: AuthLabelTextHelper.getLabelText,
            resetPasswordRequest: {
                username: '',
                newPassword: '',
                resetPasswordCode: '',
                createdUserId: ''
            }
        };
    },
    computed: {
        signedIn() {
            return !_.isEqual(this.authStore.currentUser, LocalStorageHelper.defaultEmptyItemJson);
        }
    },
    async created() {
        if (this.signedIn) {
            await this.$router.push({ path: PathDefinition.home });
        }
    },
    mounted() {
        this.$refs.username.focus();
    },
    methods: {
        async onNextClick() {
            const { valid } = await this.$refs.inputForm.validate();
            if (valid) {
                this.loading = true;
                await this.forgotPassword(this.resetPasswordRequest);
                this.loading = false;
            }
        },
        async forgotPassword(request: any) {
            const username: string = Formatter.lowerCaseFormatter(request.username);
            const response = await this.authStore.forgotPassword(username);
            if (response.success) {
                this.data.forgotPasswordStep = 'resetPassword';
            } else {
                this.data.errorAlertMessage = response.errors;
                this.$refs.errorAlert.showAlert();
            }
        },
        async onResetClick() {
            const { valid } = await this.$refs.inputFormResetPassword.validate();
            if (valid) {
                this.loading = true;
                await this.resetPassword(this.resetPasswordRequest);
                this.loading = false;
            }
        },
        async resetPassword(request: any) {
            const response = await this.authStore.forgotPasswordSubmit(
                request.username,
                request.resetPasswordCode,
                request.newPassword
            );
            if (response.success) {
                await this.$router.push({ path: PathDefinition.signIn });
            } else {
                this.errorAlertMessage = response.errors;
                this.$refs.errorAlert.showAlert();
            }
        }
    }
});
</script>
