import { ImportTableLabelTextHelper } from './labelTextHelper/ImportTableLabelTextHelper';
import { HeaderItem } from '../model/HeaderItem';

export const ImportHeaderHelper = {
    getHeaderItems(): HeaderItem[] {
        return [
            { text: ImportTableLabelTextHelper.getLabelText.status, value: 'status' },
            { text: ImportTableLabelTextHelper.getLabelText.error, value: 'error' }
        ];
    },

    getHeaders() {
        return this.getHeaderItems();
    }
};
