import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';

dayjs.extend(utc);

export const Formatter = {
    defaultDateTimeFormat: 'MMM DD hh:mm a',
    defaultDatepickerPreviewFormat: 'yyyy-MM-dd',

    convertDateFromInputToUtcDateStr(dateInput: any): string {
        return Formatter.convertToUtcDateStr(dateInput, {
            hour: 0,
            minute: 0,
            second: 0
        });
    },

    convertDateToInputToUtcDateStr(dateInput: any): string {
        return Formatter.convertToUtcDateStr(dateInput, {
            hour: 23,
            minute: 59,
            second: 59
        });
    },

    convertToUtcDateStr(dateInput: any, params: any): string {
        const dt = dayjs(dateInput);
        if (dt.isValid()) {
            const newDt = dt.set('hour', params.hour).set('minute', params.minute).set('second', params.second);
            return newDt.toISOString();
        }
        return '';
    },

    lowerCaseFormatter(str: string): string {
        if (str) {
            return str.toLowerCase();
        }
        return '';
    },

    upperCaseFormatter(str: string): string {
        if (str) {
            return str.toUpperCase();
        }
        return '';
    },

    ddlDescFormatter(code: any, list: any[]): string {
        const opt = _.find(list, { code: code });
        if (opt) {
            return _.get(opt, 'desc');
        }
        return code;
    },

    prependSearchDdl(ddlList: Object[]): Object[] {
        ddlList.unshift({
            desc: 'All',
            code: ''
        });
        return ddlList;
    },

    formatTimeToDefault: function (iso8601Time: string) {
        const dt = dayjs(iso8601Time);
        return dt.format(Formatter.defaultDateTimeFormat);
    }
};
