<template>
    <v-row justify="center" align="center">
        <v-col cols="12" sm="12" md="12">
            <v-row>
                <v-col cols="12" sm="12" md="8">
                    <h1>Shipment</h1>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                    <v-text-field
                        v-model="data.quickSearchVal"
                        :label="'Quick Search'"
                        @keydown="onKeydownQuickSearch"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-expansion-panels v-model="data.panel" multiple>
                <v-expansion-panel>
                    <v-expansion-panel-title>
                        <h2>Select date range</h2>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <label> {{ formLabelText.dateCreateOnFrom }} </label>
                                <Datepicker
                                    v-model="data.searchFilterDateRangeGrp.dateCreateOn.from"
                                    :enable-time-picker="false"
                                    :format="datepickerPreviewFormat"
                                ></Datepicker>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <label> {{ formLabelText.dateCreateOnTo }} </label>
                                <Datepicker
                                    v-model="data.searchFilterDateRangeGrp.dateCreateOn.to"
                                    :enable-time-picker="false"
                                    :format="datepickerPreviewFormat"
                                ></Datepicker>
                            </v-col>
                        </v-row>
                        <v-row class="mt-2">
                            <v-btn class="mr-2" variant="text" @click="onResetClick">
                                <v-icon>mdi-cached</v-icon>
                                Clear
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn class="mr-2" variant="text" @click="onSearchClick">
                                <v-icon>mdi-magnify</v-icon>
                                Search
                            </v-btn>
                        </v-row>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            <alert-message ref="errorAlert" :message="data.errorAlertMessage"></alert-message>
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col cols="12" sm="12" md="6">
                            <v-btn class="mt-2" variant="text" @click="onImportClick"> Create by import </v-btn>
                            <v-btn class="mr-2" text @click="onSearchClick">
                                <v-icon>mdi-cached</v-icon>
                                Refresh
                            </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-spacer></v-spacer>
                            <v-btn class="mr-2" color="warning" @click="onBulkUpdateShipmentStatusClick">
                                Bulk update status
                            </v-btn>
                            <export-to-csv
                                :data="data.dataList"
                                :csv-header="data.csvExportConfig.csvHeader"
                                :filename="data.csvExportConfig.filename"
                                :formatter="csvFormatter"
                            ></export-to-csv>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-table>
                        <thead>
                            <tr>
                                <th class="text-left" v-for="header in headers" :key="header.value">
                                    {{ header.text }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data.dataList" :key="item.shipmentNumber">
                                <td v-for="header in headers" :key="header.value">
                                    <template v-if="header.value !== shipmentActionHeaderItem.value">
                                        {{ formatCellValue(item, header) }}
                                    </template>
                                    <v-btn
                                        v-else-if="canUpdateShipmentStatus(item)"
                                        variant="text"
                                        icon="mdi-truck"
                                        @click="onUpdateShipmentStatusClick(item)"
                                    >
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
            </v-card>
        </v-col>
        <v-dialog v-model="data.onUpdateShipmentStatusDialog" max-width="600">
            <v-card>
                <v-card-title>
                    <span>{{ data.requestItem.shipmentReference }}: Mark as ready to ship</span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text> </v-card-text>
                <v-card-actions>
                    <v-btn text @click="closeUpdateShipmentStatusDialog"> Cancel</v-btn>

                    <v-spacer></v-spacer>

                    <v-btn color="success" text @click="updateShipmentStatus"> Confirm </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import * as _ from 'lodash';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { FilterHelper } from '../utils/FilterHelper';
import { fetchWrapper } from '../utils/FetchWrapper';
import { ShipmentLabelTextHelper } from '../utils/labelTextHelper/ShipmentLabelTextHelper';
import { Formatter } from '../utils/Formatter';
import { PathDefinition } from '../utils/PathDefinition';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import AlertMessage from '../components/AlertMessage.vue';
import { MasterDataApiCaller } from '../utils/MasterDataApiCaller';
import ExportToCsv from '../components/ExportToCsv.vue';
import { ShipmentHeaderHelper } from '../utils/ShipmentHeaderHelper';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

export default defineComponent({
    name: 'ListShipment',

    components: {
        ExportToCsv,
        Datepicker,
        AlertMessage
    },

    setup() {
        const data = reactive({
            onUpdateShipmentStatusDialog: false,
            panel: [0],
            quickSearchVal: '',
            errorAlertMessage: '',
            searchFilterDateRangeGrpDefault: {},
            searchFilterDateRangeGrp: {
                dateCreateOn: {
                    from: null,
                    to: null
                }
            },
            dataListApi: [],
            dataList: [],
            requestItem: {
                shipmentReference: ''
            },
            updateShipmentStatusRequestDefault: {},
            updateShipmentStatusRequest: {
                shipmentNumber: ''
            },
            csvExportConfig: {
                filename: 'exportShipment.csv',
                csvHeader: [...ShipmentHeaderHelper.getCsvHeader()]
            }
        });
        return {
            datepickerPreviewFormat: Formatter.defaultDatepickerPreviewFormat,
            formLabelText: ShipmentLabelTextHelper.getLabelText,
            shipmentStatusText: {},
            shipmentActionHeaderItem: ShipmentHeaderHelper.getActionHeaderItem(),
            headers: [...ShipmentHeaderHelper.getHeaders()],
            courierCodeList: [],
            data
        };
    },
    computed: {},
    async created() {
        this.data.updateShipmentStatusRequestDefault = _.cloneDeep(this.data.updateShipmentStatusRequest);
        this.data.searchFilterDateRangeGrpDefault = _.cloneDeep(this.data.searchFilterDateRangeGrp);
        this.shipmentStatusText = await MasterDataApiCaller.getMasterShipmentStatus();
        this.courierCodeList = await MasterDataApiCaller.getMasterCourierList();
    },
    methods: {
        formatCellValue(item: any, header: { value: string; format?: string }) {
            if (header.value === this.shipmentActionHeaderItem.value) {
                return '';
            }
            const value = item[header.value];
            if (header.format === 'datetime') {
                return this.formatTime(value);
            }
            return value;
        },
        onKeydownQuickSearch(evt: any) {
            if (evt.key === 'Enter') {
                this.runQuickSearch();
            }
        },
        runQuickSearch() {
            const searchKey = _.trim(this.data.quickSearchVal);
            this.quickSearch(searchKey);
        },
        quickSearch(searchKey: string) {
            if (!searchKey) {
                return;
            }

            this.data.dataList = FilterHelper.filterByTextSearchKeyOperator(
                this.data.dataListApi,
                searchKey,
                FilterHelper.getFilterOperator.containsOperator
            );
        },
        onResetClick() {
            this.data.searchFilterDateRangeGrp = _.cloneDeep(this.data.searchFilterDateRangeGrpDefault);
            this.data.dataList = [];
        },
        async onSearchClick() {
            const params = {};
            params['dateCreateOnFrom'] = Formatter.convertDateFromInputToUtcDateStr(
                this.data.searchFilterDateRangeGrp.dateCreateOn.from
            );
            params['dateCreateOnTo'] = Formatter.convertDateToInputToUtcDateStr(
                this.data.searchFilterDateRangeGrp.dateCreateOn.to
            );
            await this.refreshApiData(params);
            this.runQuickSearch();
        },
        async onImportClick() {
            await this.$router.push({ path: PathDefinition.importShipment });
        },
        async onBulkUpdateShipmentStatusClick() {
            await this.$router.push({ path: PathDefinition.importUpdateShipmentStatus });
        },
        csvFormatter: function (value: unknown, key: string, row: Record<string, unknown>): unknown {
            if (key === 'dateCreateOn') {
                return this.formatTime(value as string);
            }
            return value;
        },
        formatTime: function (iso8601Time: string) {
            const dt = dayjs(iso8601Time);
            return dt.format(Formatter.defaultDateTimeFormat);
        },
        canUpdateShipmentStatus: function (item: any) {
            return item.shipmentStatus === this.shipmentStatusText.pending;
        },
        async onUpdateShipmentStatusClick(item: any) {
            this.data.onUpdateShipmentStatusDialog = true;
            this.data.requestItem.shipmentReference = item.shipmentReference;
            this.data.updateShipmentStatusRequest.shipmentNumber = item.shipmentNumber;
        },
        async updateShipmentStatus() {
            this.closeUpdateShipmentStatusDialog();
            const request = _.pick(this.data.updateShipmentStatusRequest, ['shipmentNumber']);

            const requestUrl = `/api/customer/shipment/updateStatusReadyToShip`;
            const response = await fetchWrapper.post(requestUrl, request);
            // console.log('data', data);
            if (response.success) {
                await this.onSearchClick();
            } else {
                this.data.errorAlertMessage = response.errors;
                this.$refs.errorAlert.showAlert();
            }
            this.resetUpdateShipmentStatusRequest();
        },
        resetUpdateShipmentStatusRequest() {
            this.data.updateShipmentStatusRequest = _.cloneDeep(this.data.updateShipmentStatusRequestDefault);
        },
        closeUpdateShipmentStatusDialog() {
            this.data.onUpdateShipmentStatusDialog = false;
        },
        async refreshApiData(params) {
            this.data.dataListApi = await this.getApiData(params);
            this.data.dataList = this.data.dataListApi;
        },
        async getApiData(params) {
            const requestUrl = `/api/customer/shipment/list?dateCreateOnFrom=${params.dateCreateOnFrom}&dateCreateOnTo=${params.dateCreateOnTo}`;
            const response = await fetchWrapper.get(requestUrl, '');
            // console.log('data', data);
            if (response.success) {
                return response.result;
            }
            this.data.errorAlertMessage = response.errors;
            this.$refs.errorAlert.showAlert();
            return [];
        }
    }
});
</script>

<style>
.validation-error {
    color: red;
}
</style>
