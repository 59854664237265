import { createRouter, createWebHistory } from 'vue-router';
import ImportShipment from './pages/ImportShipment.vue';
import ListShipment from './pages/ListShipment.vue';
import ImportUpdateShipmentStatus from './pages/ImportUpdateShipmentStatus.vue';
import SignIn from './pages/SignIn.vue';
import ForgotPassword from './pages/ForgotPassword.vue';
import Home from './pages/Home.vue';
import Account from './pages/Account.vue';
import { useAuthStore } from './stores/AuthStore';
import { PathDefinition } from './utils/PathDefinition';

const routes = [
    {
        path: PathDefinition.home,
        name: 'Home',
        component: Home,
        meta: { layout: 'DefaultLayout' }
    },
    {
        path: PathDefinition.account,
        name: 'Account',
        component: Account,
        meta: { layout: 'DefaultLayout' }
    },
    {
        path: PathDefinition.signIn,
        name: 'SignIn',
        component: SignIn,
        meta: { layout: 'BlankLayout' }
    },
    {
        path: PathDefinition.forgotPassword,
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: { layout: 'BlankLayout' }
    },
    {
        path: PathDefinition.listShipment,
        name: 'ListShipment',
        component: ListShipment,
        meta: { layout: 'DefaultLayout' }
    },
    {
        path: PathDefinition.importShipment,
        name: 'ImportShipment',
        component: ImportShipment,
        meta: { layout: 'DefaultLayout' }
    },
    {
        path: PathDefinition.importUpdateShipmentStatus,
        name: 'ImportUpdateShipmentStatus',
        component: ImportUpdateShipmentStatus,
        meta: { layout: 'DefaultLayout' }
    }
];

const router = createRouter({ history: createWebHistory(), routes });

router.beforeEach(async (to) => {
    const publicPages = [PathDefinition.signIn, PathDefinition.forgotPassword];
    const authRequired = !publicPages.includes(to.path);
    const auth = useAuthStore();

    if (authRequired && !auth.currentUser) {
        auth.returnUrl = to.fullPath;
        return PathDefinition.signIn;
    }
});
export default router;
