import { defineStore } from 'pinia';
import { LocalStorageHelper } from '../utils/LocalStorageHelper';
import {
    getCurrentUser,
    signIn,
    confirmSignIn,
    signOut,
    resetPassword,
    confirmResetPassword,
    fetchAuthSession
} from 'aws-amplify/auth';
import router from '../router';
import * as _ from 'lodash';
import { PathDefinition } from '../utils/PathDefinition';

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        accessToken: null as string | null,
        currentUser: JSON.parse(LocalStorageHelper.getItemByKey(LocalStorageHelper.keyCurrentUser)),
        returnUrl: ''
    }),
    actions: {
        async signIn(username: string, password: string) {
            try {
                try {
                    const currentUser = await getCurrentUser();
                    if (currentUser) {
                        await this.signOut(false);
                    }
                } catch (error: any) {
                    console.log('getCurrentUser error: ', error);
                    const errorName = _.get(error, 'name', '');
                    const errorMessage = _.get(error, 'message', '');
                    if (errorName === 'UserUnAuthenticatedException') {
                        console.log('UserUnAuthenticatedException error, skip and go on');
                    } else {
                        const errMsg = `getCurrentUser unknown error! ${error}`;
                        console.log('errMsg', errMsg);
                        return this.createErrorResponse(error);
                    }
                }

                const response = await signIn({ username, password });
                console.log('SignIn done');
                // console.log('signIn response', response);
                const isSignedIn: any = _.get(response, 'isSignedIn', false);
                const nextStep: any = _.get(response, 'nextStep', {});
                if (isSignedIn) {
                    await this.storeUser(response);
                }
                return {
                    success: true,
                    nextStep: nextStep,
                    signInUser: { username: username }
                };
            } catch (error) {
                const errorName = _.get(error, 'name', '');
                const errorMessage = _.get(error, 'message', '');
                const errMsg = `Sign in error! ${errorName} ${errorMessage}`;
                console.log('errMsg', errMsg);
                return this.createErrorResponse(error);
            }
        },
        async completeNewPassword(user: any, newPassword: string) {
            try {
                const response = await confirmSignIn({ challengeResponse: newPassword });
                console.log('Complete new password successfully');
                // console.log('completeNewPassword response', response);
                await this.storeUser(response);
                return { success: true };
            } catch (error) {
                const errMsg = `Complete new password: error! ${error}`;
                console.log('errMsg', errMsg);
                return this.createErrorResponse(error);
            }
        },
        async storeUser(response: any) {
            try {
                const currentUser = await getCurrentUser();
                // console.log('storeUser currentUser', currentUser);
                const accessToken = await this.getAccessToken();
                this.currentUser = {
                    username: _.get(currentUser, 'signInDetails.username', ''),
                    email: _.get(currentUser, 'signInDetails.loginId', ''),
                    accessToken: accessToken
                };

                localStorage.setItem(LocalStorageHelper.keyCurrentUser, JSON.stringify(this.currentUser));

                await router.push(this.returnUrl || PathDefinition.account);
            } catch (error) {
                const errMsg = `Store User error! ${error}`;
                console.log('errMsg', errMsg);
                throw error;
            }
        },
        async getAccessToken(): Promise<string | null> {
            try {
                const response = await fetchAuthSession();
                // console.log('fetchAuthSession response', response);
                const { accessToken } = response.tokens ?? {};
                this.accessToken = accessToken?.toString() ?? null;
                return this.accessToken;
            } catch (error) {
                const errMsg = `getAccessToken error! ${error}`;
                console.log('errMsg', errMsg);
                throw error;
            }
        },
        async forgotPassword(username: string) {
            try {
                const response = await resetPassword({ username });
                console.log('Reset Password initiated successfully');
                console.log('forgotPassword response', response);
                return {
                    success: true
                };
            } catch (error) {
                const errMsg = `Reset Password: error! ${error}`;
                console.log('errMsg', errMsg);
                return this.createErrorResponse(error);
            }
        },
        async forgotPasswordSubmit(username: string, code: string, newPassword: string) {
            try {
                const response = await confirmResetPassword({ username, confirmationCode: code, newPassword });
                console.log('Reset Password confirmed successfully');
                console.log('forgotPasswordSubmit response', response);
                return {
                    success: true
                };
            } catch (error) {
                const errMsg = `Confirm Reset Password: error! ${error}`;
                console.log('errMsg', errMsg);
                return this.createErrorResponse(error);
            }
        },
        async signOut(redirect: boolean = true) {
            try {
                const response = await signOut();
                this.currentUser = LocalStorageHelper.defaultEmptyItemJson;
                localStorage.removeItem(LocalStorageHelper.keyCurrentUser);
                if (redirect) {
                    await router.push(PathDefinition.signIn);
                }
            } catch (error) {
                const errMsg = `Sign out error! ${error}`;
                console.log('errMsg', errMsg);
            }
        },
        createErrorResponse(error: any) {
            return {
                success: false,
                errors: error
            };
        }
    }
});
