import _ from 'lodash';
import { Formatter } from './Formatter';

export const FilterHelper = {
    getFilterOperator: {
        containsOperator: 'contains',
        startsWithOperator: 'startsWith'
    },

    filterByTextFiltersExactMatch(sourceList: any[], searchFilterTextGrp: any): any[] {
        const params = _.pickBy(searchFilterTextGrp, _.identity);
        const keys = _.keys(params);
        return _.filter(sourceList, (v) => _.isEqual(_.pick(v, keys), params));
    },

    filterByTextFiltersContains(sourceList: any[], searchFilterContainsTextGrp: any): any[] {
        const params = _.pickBy(searchFilterContainsTextGrp, _.identity);
        return _.filter(sourceList, (item) =>
            _.every(params, (searchValue, key) => {
                const searchLower = Formatter.lowerCaseFormatter(searchValue);
                const target = _.get(item, key);
                const targetLower = Formatter.lowerCaseFormatter(target);
                return _.includes(targetLower, searchLower);
            })
        );
    },

    filterByTextSearchKeyOperator(sourceList: any[], searchKey: string, operator: string): any[] {
        return _.filter(sourceList, (item) => {
            const searchLower = Formatter.lowerCaseFormatter(searchKey);
            return _.some(item, (v) => {
                const target = _.toString(v);
                const targetLower = Formatter.lowerCaseFormatter(target);
                switch (operator) {
                    case FilterHelper.getFilterOperator.containsOperator:
                        return _.includes(targetLower, searchLower);
                    case FilterHelper.getFilterOperator.startsWithOperator:
                        return _.startsWith(targetLower, searchLower);
                    default:
                        const msg = `invalid operator: ${operator}`;
                        // alert(msg);
                        console.log(msg);
                        break;
                }
            });
        });
    }
};
