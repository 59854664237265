export const ImportTableLabelTextHelper = {
    getLabelText: {
        status: 'Import Status',
        error: 'Import Error'
    },
    getImportResultValue: {
        initial: 'pending',
        success: 'success',
        error: 'error'
    }
};
