<template>
    <component :is="layout" />
</template>

<script lang="ts">
import BlankLayout from './layouts/BlankLayout.vue';
import DefaultLayout from './layouts/DefaultLayout.vue';
import _ from 'lodash';
import { useAuthStore } from './stores/AuthStore';
import { LocalStorageHelper } from './utils/LocalStorageHelper';
import { PathDefinition } from './utils/PathDefinition';

export default {
    name: 'App',
    components: {
        BlankLayout,
        DefaultLayout
    },
    data() {
        return {
            layout: null,
            authStore: useAuthStore()
        };
    },
    watch: {
        $route(to) {
            if (to.meta.layout !== undefined) {
                this.layout = to.meta.layout;
            } else {
                this.layout = 'BlankLayout';
            }
        }
    },
    computed: {
        signedIn(): boolean {
            return !_.isEqual(this.authStore.currentUser, LocalStorageHelper.defaultEmptyItemJson);
        }
    },
    async mounted() {
        if (!this.signedIn) {
            await this.$router.push({ path: PathDefinition.signIn });
        }
    }
};
</script>
