export const AuthLabelTextHelper = {
    getLabelText: {
        username: 'Email',
        password: 'Password',
        confirmationCode: 'Confirmation Code',
        resetPasswordCode: 'Reset Password Code',
        newPassword: 'New Password'
    },
    getUserStatusCode: {
        active: 'active',
        inactive: 'inactive'
    },
    getUserTypeCode: {
        customer: 'customer',
        operation: 'operation'
    }
};
