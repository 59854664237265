import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import { Amplify } from 'aws-amplify';
import AmplifyConfigParam from './config/AmplifyConfigParam';
import { createPinia } from 'pinia';
import { VueCsvImportPlugin } from 'vue-csv-import';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

Amplify.configure(AmplifyConfigParam);

// await loadFonts();

createApp(App)
    .use(router)
    .use(createPinia())
    .use(vuetify)
    .use(VueCsvImportPlugin)
    .component('Datepicker', Datepicker)
    .mount('#app');
