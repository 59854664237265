import * as _ from 'lodash';
import { fetchWrapper } from './FetchWrapper';

export const MasterDataApiCaller = {
    requestBaseUrl: '/api/public/masterData',

    getMasterShipmentStatus: async (): Promise<Object> => {
        const response = await fetchWrapper.get(`${MasterDataApiCaller.requestBaseUrl}/getMasterShipmentStatus`, '');
        return MasterDataApiCaller.handleResponseResultMap(response);
    },
    getMasterCourierList: async (): Promise<string[]> => {
        const response = await fetchWrapper.get(`${MasterDataApiCaller.requestBaseUrl}/getMasterCourierList`, '');
        return MasterDataApiCaller.handleResponseResultList(response);
    },
    handleResponseResultMap: (response: any): Object => {
        return _.get(response, 'result', {});
    },
    handleResponseResultList: (response: any): any[] => {
        return _.get(response, 'result', []);
    }
};
