<template>
    <v-sheet class="ma-9 pa-9">
        <v-card width="500" max-width="500" height="700" max-height="700">
            <v-card-text>
                <v-img
                    src="/ec-parcel-logo-v2.png"
                    :max-width="$vuetify.display.smAndDown ? '150px' : '250px'"
                    class="mx-auto mb-6"
                    contain
                ></v-img>
                <h1 class="text-center ma-2 pa-2">First Time Sign In</h1>
                <div class="text-center mb-2">Please update your password to complete sign up.</div>
                <v-sheet class="ma-3 pa-3"></v-sheet>
                <v-form ref="inputFormCompleteNewPassword" v-model="data.isValidCompleteNewPassword">
                    <v-text-field
                        v-model="newPassword"
                        :label="formLabelText.newPassword"
                        type="password"
                        :rules="passwordRules"
                        :disabled="loading"
                        @keydown="onKeydownUpdate"
                    ></v-text-field>
                    <v-sheet>
                        <v-btn
                            color="primary"
                            :loading="loading"
                            :disabled="loading || !data.isValidCompleteNewPassword"
                            @click="onUpdateClick"
                        >
                            Update
                        </v-btn>
                    </v-sheet>
                </v-form>
            </v-card-text>
        </v-card>
        <v-sheet class="ma-3 pa-3">
            <alert-message ref="errorAlert" :message="data.errorAlertMessage"></alert-message>
        </v-sheet>
    </v-sheet>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { useAuthStore } from '../stores/AuthStore';
import { ValidationHelper } from '../utils/ValidationHelper';
import { AuthLabelTextHelper } from '../utils/labelTextHelper/AuthLabelTextHelper';
import AlertMessage from '../components/AlertMessage.vue';

export default defineComponent({
    name: 'CompleteNewPassword',

    components: {
        AlertMessage
    },

    props: {
        user: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const authStore = useAuthStore();
        const data = reactive({
            errorAlertMessage: '',
            isValidCompleteNewPassword: false
        });
        return {
            authStore,
            data,
            props
        };
    },
    data() {
        return {
            loading: false,
            passwordRules: [ValidationHelper.getRequiredRule()],
            formLabelText: AuthLabelTextHelper.getLabelText,
            newPassword: ''
        };
    },
    methods: {
        async onKeydownUpdate(evt: any) {
            if (evt.key === 'Enter') {
                await this.onUpdateClick();
            }
        },
        async onUpdateClick() {
            const { valid }: { valid: boolean } = await this.$refs.inputFormCompleteNewPassword.validate();
            if (valid) {
                this.loading = true;
                await this.completeNewPassword(this.user, this.newPassword);
                this.loading = false;
            }
        },
        async completeNewPassword(signInUser: any, newPassword: string) {
            const response = await this.authStore.completeNewPassword(signInUser, newPassword);

            if (!response.success) {
                this.data.errorAlertMessage = response.errors;
                this.$refs.errorAlert.showAlert();
            }
        }
    }
});
</script>
