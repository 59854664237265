<template>
    <v-layout>
        <v-app-bar>
            <v-app-bar-nav-icon variant="text" @click.stop="data.drawer = !data.drawer"></v-app-bar-nav-icon>
            <v-app-bar-title
                ><v-img class="ecparcel-header-logo" src="/ec-parcel-logo-v2.png" max-width="60" contain></v-img
            ></v-app-bar-title>
        </v-app-bar>
        <v-navigation-drawer v-model="data.drawer" permanent>
            <v-list nav>
                <v-list-item
                    v-for="(item, i) in data.items"
                    :key="i"
                    :to="item.to"
                    :prepend-icon="item.icon"
                    :title="item.title"
                    :value="item.value"
                ></v-list-item>
            </v-list>

            <template v-slot:append>
                <div class="pa-2">
                    <v-btn block @click="onSignOutClick"> Sign out </v-btn>
                </div>
            </template>
        </v-navigation-drawer>
        <v-main>
            <v-container>
                <router-view />
            </v-container>
        </v-main>
    </v-layout>
</template>

<script>
import { reactive } from 'vue';
import { useAuthStore } from '../stores/AuthStore';
import _ from 'lodash';
import { LocalStorageHelper } from '../utils/LocalStorageHelper';
import { PathDefinition } from '../utils/PathDefinition';

export default {
    name: 'DefaultLayout',
    setup() {
        const authStore = useAuthStore();
        const data = reactive({
            drawer: true,
            group: null,
            items: [
                {
                    icon: 'mdi-account-circle',
                    title: 'Account',
                    value: 'account',
                    to: PathDefinition.account
                },
                {
                    icon: 'mdi-truck',
                    title: 'Shipment List',
                    value: 'listShipment',
                    to: PathDefinition.listShipment
                }
            ]
        });
        return {
            authStore,
            data
        };
    },
    data() {
        return {
            pathHome: PathDefinition.home
        };
    },
    computed: {
        signedIn() {
            return !_.isEqual(this.authStore.currentUser, LocalStorageHelper.defaultEmptyItemJson);
        }
    },
    methods: {
        async onSignInClick() {
            await this.$router.push({ path: PathDefinition.signIn });
        },
        async onSignOutClick() {
            // confirm dialog then sign out
            await this.signOut();
        },
        async signOut() {
            await this.authStore.signOut();
        }
    },
    watch: {
        group() {
            this.data.drawer = false;
        }
    }
};
</script>

<style scoped>
.ecparcel-header-logo {
    height: 64px;
    object-fit: contain;
    object-position: left center;
}
</style>
