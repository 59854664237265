import { SharedMsgHelper } from './SharedMsgHelper';
import * as _ from 'lodash';
import dayjs from 'dayjs';

export const ValidationHelper = {
    getRequiredRule(): any {
        const rule: any = (value: string) => !!value || SharedMsgHelper.getMissingRequiredErrMsg;
        return rule;
    },
    getValidEmailRule(): any {
        const rule: any = (v: string) => /.+@.+\..+/.test(v) || SharedMsgHelper.getInvalidEmailErrMsg;
        return rule;
    },
    getValidPasswordRule(): any {
        const rule: any = (v: string) =>
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(v) ||
            SharedMsgHelper.getInvalidPasswordErrMsg;
        return rule;
    },
    getNotAllowWhitespaceRule(): any {
        const rule: any = (value: string) => /^\S*$/.test(value) || SharedMsgHelper.getNotAllowWhitespaceErrMsg;
        return rule;
    },
    isAlphanumericString(value: string): boolean {
        return /^[a-zA-Z0-9]*$/.test(value);
    },
    isValidCourierCode(code: string, courierCodeList: string[]): boolean {
        return _.indexOf(courierCodeList, code) >= 0;
    },
    isIsoUtcTimeString(value: string): boolean {
        return dayjs(value, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', true).isValid();
    }
};
