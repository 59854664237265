<template>
    <v-row justify="center" align="center">
        <v-col cols="12" sm="12" md="12">
            <alert-message ref="errorAlert" :message="data.errorAlertMessage"></alert-message>
            <h1>Hi {{ currentUser.email }}!</h1>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { useAuthStore } from '../stores/AuthStore';
import AlertMessage from '../components/AlertMessage.vue';

export default defineComponent({
    name: 'Account',

    components: {
        AlertMessage
    },

    setup() {
        const authStore = useAuthStore();
        const data = reactive({
            errorAlertMessage: '',
            username: '',
            password: ''
        });
        return {
            authStore,
            data
        };
    },
    data() {
        return {
            account: {
                currentBalanceValue: 0,
                extraUsage: 0
            },
            currentUser: {
                email: ''
            }
        };
    },
    async created() {
        const { currentUser } = useAuthStore();
        this.currentUser = currentUser;
    },
    methods: {}
});
</script>
