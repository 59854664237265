export const SharedMsgHelper = {
    getInvalidPasswordErrMsg: 'Minimum 8 characters, at least one letter, one number and one special character',
    getInvalidEmailErrMsg: 'Email must be valid',
    getMissingRequiredErrMsg: 'Required!!',
    getNotAllowWhitespaceErrMsg: 'Not allow whitespace',
    getNotAlphanumericErrMsg: 'Alphanumeric Only',
    getInvalidCouriercodeErrMsg: 'Invalid courier code',
    getInvalidcheckpointTimeUtcFormatErrMsg: 'Invalid Checkpoint time format! Must be UTC ISO 8601 format',
    getImportDoneMsg: 'Import process completed! Please check the result'
};
