<template>
    <div>
        <h1>
            Hi {{ needToSignIn ? '' : this.authStore.currentUser?.email }}!
            {{ needToSignIn ? 'Please sign in.' : 'Welcome to ec-Tracks!!' }}
        </h1>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useAuthStore } from '../stores/AuthStore';
import _ from 'lodash';
import { LocalStorageHelper } from '../utils/LocalStorageHelper';

export default defineComponent({
    name: 'Home',

    setup() {
        const authStore = useAuthStore();
        return {
            authStore
        };
    },
    computed: {
        needToSignIn() {
            return _.isEqual(this.authStore.currentUser, LocalStorageHelper.defaultEmptyItemJson);
        }
    }
});
</script>
