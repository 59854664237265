<template>
    <div>
        <v-alert type="error" closable v-model="show">
            {{ message }}
        </v-alert>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AlertMessage',
    props: ['message'],
    setup(props) {
        return props;
    },
    data() {
        return {
            show: false
        };
    },
    methods: {
        showAlert() {
            this.show = true;
        }
    }
});
</script>
