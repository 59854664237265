<template>
    <div>
        <v-btn :color="btnColor" class="mr-2" @click="onReferenceClickDialog = true"> {{ btnText }} </v-btn>
        <v-dialog v-model="onReferenceClickDialog" max-width="400">
            <v-card>
                <v-card-title>
                    <span>{{ title }}</span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <h3>{{ description }}</h3>
                    <p></p>
                    <v-row>
                        <v-col cols="12" sm="12" md="4">
                            <b>{{ itemCodeHeader }}</b>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <b>{{ itemDescHeader }}</b>
                        </v-col>
                    </v-row>
                    <div v-for="(item, idx) in referenceList" :key="item.code">
                        <v-row>
                            <v-col cols="12" sm="12" md="4">
                                <span>{{ item.code }}</span>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <span>{{ item.desc }}</span>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'CommonListReference',
    props: ['btnColor', 'btnText', 'title', 'description', 'itemCodeHeader', 'itemDescHeader', 'referenceList'],
    setup(props) {
        return props;
    },
    data() {
        return {
            onReferenceClickDialog: false
        };
    }
});
</script>
