<template>
    <v-row justify="center" align="center">
        <v-col cols="12" sm="12" md="12">
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col cols="12" sm="12" md="6"> Create Shipment by Import </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <common-list-reference
                                :btn-color="courierReferenceProps.btnColor"
                                :btn-text="courierReferenceProps.btnText"
                                :title="courierReferenceProps.title"
                                :description="courierReferenceProps.description"
                                :item-code-header="courierReferenceProps.itemCodeHeader"
                                :item-desc-header="courierReferenceProps.itemDescHeader"
                                :reference-list="courierReferenceProps.referenceList"
                            ></common-list-reference>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <p>
                        <b>Only accept csv file. </b><a href="/createShipmentByImportSample.csv">Download the Sample</a>
                    </p>
                    <p>1. Choose your file</p>
                    <p>2. SELECT THE CORRECT MAPPING OF Field and Column</p>
                    <p>3. IMPORT</p>
                    <vue-csv-import v-model="data.mappedCsv" :fields="data.fields">
                        <vue-csv-toggle-headers></vue-csv-toggle-headers>
                        <vue-csv-errors></vue-csv-errors>
                        <div>
                            <vue-csv-input></vue-csv-input>
                        </div>
                        <vue-csv-map></vue-csv-map>
                    </vue-csv-import>
                </v-card-text>
            </v-card>
            <br />
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-btn
                                class="mr-2"
                                color="primary"
                                :loading="loading"
                                :disabled="loading || invalidFile"
                                @click="onImportClick"
                            >
                                <v-icon>mdi-cloud-upload</v-icon>
                                Import
                            </v-btn>
                            <v-btn class="mr-2" :to="pathListShipment"> Back to list </v-btn>
                            <span v-if="importDone">{{ importDoneMsg }} </span>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-table>
                        <thead>
                            <tr>
                                <th class="text-left" v-for="header in data.headers" :key="header.value">
                                    {{ header.text }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, idx) in transformMappedCsv(data.mappedCsv)"
                                :key="`row${idx}`"
                                :class="invalidRowClass(item)"
                            >
                                <td v-for="header in data.headers" :key="header.value">
                                    {{ item[header.value] }}
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { fetchWrapper } from '../utils/FetchWrapper';
import { ShipmentLabelTextHelper } from '../utils/labelTextHelper/ShipmentLabelTextHelper';
import * as _ from 'lodash';
import { PathDefinition } from '../utils/PathDefinition';
import { ImportTableLabelTextHelper } from '../utils/labelTextHelper/ImportTableLabelTextHelper';
import { SharedMsgHelper } from '../utils/SharedMsgHelper';
import { ValidationHelper } from '../utils/ValidationHelper';
import CommonListReference from '../components/CommonListReference.vue';
import { Formatter } from '../utils/Formatter';
import { MasterDataApiCaller } from '../utils/MasterDataApiCaller';
import { ShipmentHeaderHelper } from '../utils/ShipmentHeaderHelper';
import { ImportHeaderHelper } from '../utils/ImportHeaderHelper';

export default defineComponent({
    name: 'ImportShipment',

    components: {
        CommonListReference
    },

    setup() {
        const data = reactive({
            mappedCsv: null,
            fields: ShipmentHeaderHelper.getImportShipmentFields(),
            headers: [...ImportHeaderHelper.getHeaders(), ...ShipmentHeaderHelper.getImportShipmentHeaders()]
        });
        return {
            data
        };
    },
    data() {
        return {
            loading: false,
            invalidFile: false,
            importDone: false,
            importDoneMsg: SharedMsgHelper.getImportDoneMsg,
            pathListShipment: PathDefinition.listShipment,
            formLabelText: ShipmentLabelTextHelper.getLabelText,
            courierCodeList: [],
            courierReferenceProps: {
                btnColor: 'secondary',
                btnText: 'Courier List',
                title: 'Courier List For Reference',
                description: '',
                itemCodeHeader: '',
                itemDescHeader: 'Courier',
                referenceList: []
            }
        };
    },
    async created() {
        this.courierCodeList = await MasterDataApiCaller.getMasterCourierList();
        this.courierReferenceProps.referenceList = _.map(this.courierCodeList, (code) => {
            return {
                code: '',
                desc: code
            };
        });
    },
    methods: {
        transformMappedCsv(mappedCsv) {
            const importHeaderValues = this.data.headers.map((header) => header.value);
            const requiredFields = [
                'shipmentName',
                'shipmentPhone',
                'shipmentEmail',
                'shipmentAddress1',
                'shipmentAddress2',
                'shipmentPostalcode',
                'shipmentCity',
                'shipmentState',
                'shipmentCountry',
                'shipmentCourierCode'
            ];
            const lowercaseFields = ['shipmentCourierCode', 'shipmentCountry'];
            return _.map(mappedCsv, (item) => {
                let result = _.mapValues(_.pick(item, importHeaderValues), (value) => _.trim(value));

                lowercaseFields.forEach((field) => {
                    result[field] = Formatter.lowerCaseFormatter(result[field]);
                });

                requiredFields.forEach((field) => {
                    if (!result[field]) {
                        result[field] = SharedMsgHelper.getMissingRequiredErrMsg;
                    }
                });

                if (
                    result.shipmentCourierCode &&
                    !ValidationHelper.isValidCourierCode(result.shipmentCourierCode, this.courierCodeList)
                ) {
                    result.shipmentCourierCode = SharedMsgHelper.getInvalidCouriercodeErrMsg;
                }

                return result;
            });
        },
        async onImportClick() {
            this.data.mappedCsv = this.transformMappedCsv(this.data.mappedCsv);
            if (this.data.mappedCsv && this.data.mappedCsv.length > 0) {
                await this.importSubmit();
            } else {
                console.log('empty csv');
            }
        },
        async importSubmit() {
            this.loading = true;
            const isValid = this.validation(this.data.mappedCsv);
            if (isValid) {
                for (const item in this.data.mappedCsv) {
                    await this.create(item);
                }
                this.importDone = true;
                // prevent import again
                this.invalidFile = true;
            } else {
                console.log('invalid csv');
            }
            this.loading = false;
        },
        validation(list) {
            return _.every(list, (v) => {
                return !this.isInvalidRow(v);
            });
        },
        invalidRowClass(item) {
            if (this.isInvalidRow(item)) {
                return 'row-validation-error';
            }
            return '';
        },
        isInvalidRow(item) {
            return (
                this.isInvalidRowContent(item) || item.status === ImportTableLabelTextHelper.getImportResultValue.error
            );
        },
        isInvalidRowContent(item) {
            return (
                item.shipmentName === SharedMsgHelper.getMissingRequiredErrMsg ||
                item.shipmentPhone === SharedMsgHelper.getMissingRequiredErrMsg ||
                item.shipmentEmail === SharedMsgHelper.getMissingRequiredErrMsg ||
                item.shipmentAddress1 === SharedMsgHelper.getMissingRequiredErrMsg ||
                item.shipmentAddress2 === SharedMsgHelper.getMissingRequiredErrMsg ||
                item.shipmentPostalcode === SharedMsgHelper.getMissingRequiredErrMsg ||
                item.shipmentCity === SharedMsgHelper.getMissingRequiredErrMsg ||
                item.shipmentState === SharedMsgHelper.getMissingRequiredErrMsg ||
                item.shipmentCountry === SharedMsgHelper.getMissingRequiredErrMsg ||
                item.shipmentCourierCode === SharedMsgHelper.getMissingRequiredErrMsg ||
                item.shipmentCourierCode === SharedMsgHelper.getInvalidCouriercodeErrMsg
            );
        },
        async create(idx) {
            const csvData = this.data.mappedCsv[idx];
            const response = await this.createShipment(csvData);
            this.processResponse(response, idx, csvData);
        },
        async createShipment(csvData) {
            const requestFields = ShipmentHeaderHelper.getImportShipmentHeaders().map((header) => header.value);
            const request = _.pick(csvData, requestFields);
            const requestUrl = `/api/customer/shipment/create`;
            return await fetchWrapper.post(requestUrl, request);
        },
        processResponse(response, idx, csvData) {
            if (response.success) {
                csvData.status = ImportTableLabelTextHelper.getImportResultValue.success;
                csvData.shipmentNumber = _.get(response, 'result.shipmentNumber');
            } else {
                csvData.status = ImportTableLabelTextHelper.getImportResultValue.error;
                csvData.error = response.errors;
            }
            this.data.mappedCsv.splice(idx, 1, csvData);
        }
    }
});
</script>

<style>
select {
    border: 1px solid !important;
}

.row-validation-error {
    background: red;
}
</style>
