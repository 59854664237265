import { useAuthStore } from '../stores/AuthStore';
import * as _ from 'lodash';
import router from '../router';
import { LocalStorageHelper } from './LocalStorageHelper';
import { PathDefinition } from './PathDefinition';

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

function request(method: string) {
    return (url: string, body: any) => {
        const requestOptions: any = {
            method: method,
            headers: authHeader()
        };
        requestOptions.headers['Content-Type'] = 'application/json';
        if (body) {
            requestOptions.body = JSON.stringify(body);
        }
        return fetch(url, requestOptions).then(handleResponse);
    };
}

function authHeader() {
    const { currentUser } = useAuthStore();
    const accessToken = _.get(currentUser, 'accessToken', '');
    return { Authorization: `Bearer ${accessToken}` };
}

async function handleResponse(response: any) {
    const status = _.get(response, 'status', 401);
    try {
        const json: any = await response.json();
        if (json.success) {
            return json;
        } else {
            const { currentUser, signOut } = useAuthStore();
            if (_.isEqual(currentUser, LocalStorageHelper.defaultEmptyItemJson)) {
                await router.push(PathDefinition.signIn);
            }
            if ([401, 403].includes(status)) {
                await signOut();
            } else {
                return errorResponse(json);
            }
        }
    } catch (error) {
        console.log('catch error', error);
        return errorResponse(error);
    }
}

function errorResponse(error: any) {
    const errMsg = constructErrorMessage(error);
    console.log('errMsg', errMsg);
    return {
        success: false,
        errors: errMsg
    };
}

function constructErrorMessage(response: any) {
    if (response.success === false && response.errors) {
        return constructErrorFromApiError(response);
    }
    return constructErrorFromGeneral(response);
}

function constructErrorFromApiError(response: any) {
    return _.map(response.errors, (err) => `${err.code}: ${err.message}`).join(',');
}

function constructErrorFromGeneral(response: any) {
    return response.message;
}
