import { ShipmentLabelTextHelper } from './labelTextHelper/ShipmentLabelTextHelper';
import { HeaderItem } from '../model/HeaderItem';

const excludedImportFieldList = [
    'shipmentNumber',
    'shipmentReference',
    'shipmentStatus',
    'trackingNumber',
    'dateCreateOn',
    'dateModifyOn'
];

export const ShipmentHeaderHelper = {
    getHeaderItems(): HeaderItem[] {
        return [
            { text: ShipmentLabelTextHelper.getLabelText.shipmentNumber, value: 'shipmentNumber' },
            { text: ShipmentLabelTextHelper.getLabelText.shipmentReference, value: 'shipmentReference' },
            { text: ShipmentLabelTextHelper.getLabelText.shipmentStatus, value: 'shipmentStatus' },
            { text: ShipmentLabelTextHelper.getLabelText.shipmentName, value: 'shipmentName' },
            { text: ShipmentLabelTextHelper.getLabelText.shipmentPhone, value: 'shipmentPhone' },
            { text: ShipmentLabelTextHelper.getLabelText.shipmentEmail, value: 'shipmentEmail' },
            { text: ShipmentLabelTextHelper.getLabelText.shipmentAddress1, value: 'shipmentAddress1' },
            { text: ShipmentLabelTextHelper.getLabelText.shipmentAddress2, value: 'shipmentAddress2' },
            { text: ShipmentLabelTextHelper.getLabelText.shipmentAddress3, value: 'shipmentAddress3' },
            { text: ShipmentLabelTextHelper.getLabelText.shipmentPostalcode, value: 'shipmentPostalcode' },
            { text: ShipmentLabelTextHelper.getLabelText.shipmentCity, value: 'shipmentCity' },
            { text: ShipmentLabelTextHelper.getLabelText.shipmentState, value: 'shipmentState' },
            { text: ShipmentLabelTextHelper.getLabelText.shipmentCountry, value: 'shipmentCountry' },
            { text: ShipmentLabelTextHelper.getLabelText.shipmentCourierCode, value: 'shipmentCourierCode' },
            { text: ShipmentLabelTextHelper.getLabelText.trackingNumber, value: 'trackingNumber' },
            { text: ShipmentLabelTextHelper.getLabelText.remarks, value: 'remarks' },
            { text: ShipmentLabelTextHelper.getLabelText.customerReference, value: 'customerReference' },
            { text: ShipmentLabelTextHelper.getLabelText.dateCreateOn, value: 'dateCreateOn', format: 'datetime' },
            { text: ShipmentLabelTextHelper.getLabelText.dateModifyOn, value: 'dateModifyOn', format: 'datetime' }
        ];
    },

    getActionHeaderItem(): HeaderItem {
        return { text: ShipmentLabelTextHelper.getLabelText.action, value: 'action' };
    },

    getCsvHeader() {
        return [
            ...this.getHeaderItems().map((item) => ({ key: item.value, text: item.text })),
            { key: 'link', text: this.getActionHeaderItem().text }
        ];
    },

    getHeaders() {
        return [...this.getHeaderItems(), this.getActionHeaderItem()];
    },

    getImportShipmentFields() {
        return this.getFilteredHeaderItems().reduce((acc, item) => {
            acc[item.value] = {
                required: false,
                label: item.text
            };
            return acc;
        }, {} as Record<string, { required: boolean; label: string }>);
    },

    getImportShipmentHeaders() {
        return this.getFilteredHeaderItems();
    },

    getFilteredHeaderItems() {
        return this.getHeaderItems().filter((header) => !excludedImportFieldList.includes(header.value));
    }
};
