<template>
    <v-row justify="center" align="center">
        <v-col cols="12" sm="12" md="12">
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col cols="12" sm="12" md="6"> Bulk Update Shipment Status by Import </v-col>
                        <v-col cols="12" sm="12" md="6">
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <p>
                        <b>Only accept csv file. </b
                        ><a href="/updateShipmentStatusByImportSample.csv">Download the Sample</a>
                    </p>
                    <p>1. Choose your file</p>
                    <p>2. SELECT THE CORRECT MAPPING OF Field and Column</p>
                    <p>3. IMPORT</p>
                    <vue-csv-import v-model="data.mappedCsv" :fields="data.fields">
                        <vue-csv-toggle-headers></vue-csv-toggle-headers>
                        <vue-csv-errors></vue-csv-errors>
                        <div>
                            <vue-csv-input></vue-csv-input>
                        </div>
                        <vue-csv-map></vue-csv-map>
                    </vue-csv-import>
                </v-card-text>
            </v-card>
            <br />
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-btn
                                class="mr-2"
                                color="primary"
                                :loading="loading"
                                :disabled="loading || invalidFile"
                                @click="onImportClick"
                            >
                                <v-icon>mdi-cloud-upload</v-icon>
                                Import
                            </v-btn>
                            <v-btn class="mr-2" :to="pathListShipment"> Back to list </v-btn>
                            <span v-if="importDone">{{ importDoneMsg }} </span>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-table>
                        <thead>
                            <tr>
                                <th class="text-left" v-for="header in data.headers" :key="header.value">
                                    {{ header.text }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, idx) in transformMappedCsv(data.mappedCsv)"
                                :key="`row${idx}`"
                                :class="invalidRowClass(item)"
                            >
                                <td>{{ item.status }}</td>
                                <td>{{ item.error }}</td>
                                <td>{{ item.shipmentNumber }}</td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { fetchWrapper } from '../utils/FetchWrapper';
import { ShipmentLabelTextHelper } from '../utils/labelTextHelper/ShipmentLabelTextHelper';
import * as _ from 'lodash';
import { PathDefinition } from '../utils/PathDefinition';
import { ImportTableLabelTextHelper } from '../utils/labelTextHelper/ImportTableLabelTextHelper';
import { SharedMsgHelper } from '../utils/SharedMsgHelper';
import { ValidationHelper } from '../utils/ValidationHelper';
import CommonListReference from '../components/CommonListReference.vue';
import { ImportHeaderHelper } from '../utils/ImportHeaderHelper';

export default defineComponent({
    name: 'ImportUpdateShipmentStatus',

    components: {
        CommonListReference
    },

    setup() {
        const data = reactive({
            mappedCsv: null,
            fields: {
                shipmentNumber: { required: false, label: ShipmentLabelTextHelper.getLabelText.shipmentNumber }
            },
            headers: [
                ...ImportHeaderHelper.getHeaders(),
                {
                    text: ShipmentLabelTextHelper.getLabelText.shipmentNumber,
                    value: 'shipmentNumber'
                }
            ]
        });
        return {
            data
        };
    },
    data() {
        return {
            loading: false,
            invalidFile: false,
            importDone: false,
            importDoneMsg: SharedMsgHelper.getImportDoneMsg,
            pathListShipment: PathDefinition.listShipment,
            formLabelText: ShipmentLabelTextHelper.getLabelText,
        };
    },
    async created() {
    },
    methods: {
        transformMappedCsv(mappedCsv) {
            return _.map(mappedCsv, (item) => {
                let result = {
                    status: item.status,
                    error: item.error,
                    shipmentNumber: _.trim(item.shipmentNumber)
                };
                result.shipmentNumber = this.checkRequiredAndAlphanumericInput(result.shipmentNumber);
                return result;
            });
        },
        checkRequiredAndAlphanumericInput(input) {
            if (!input) {
                return SharedMsgHelper.getMissingRequiredErrMsg;
            }
            if (!ValidationHelper.isAlphanumericString(input)) {
                return SharedMsgHelper.getNotAlphanumericErrMsg;
            }
            return input;
        },
        async onImportClick() {
            this.data.mappedCsv = this.transformMappedCsv(this.data.mappedCsv);
            if (this.data.mappedCsv && this.data.mappedCsv.length > 0) {
                await this.importSubmit();
            } else {
                console.log('empty csv');
            }
        },
        async importSubmit() {
            this.loading = true;
            const isValid = this.validation(this.data.mappedCsv);
            if (isValid) {
                for (const item in this.data.mappedCsv) {
                    await this.update(item);
                }
                this.importDone = true;
                // prevent import again
                this.invalidFile = true;
            } else {
                console.log('invalid csv');
            }
            this.loading = false;
        },
        validation(list) {
            return _.every(list, (v) => {
                return !this.isInvalidRow(v);
            });
        },
        invalidRowClass(item) {
            if (this.isInvalidRow(item)) {
                return 'row-validation-error';
            }
            return '';
        },
        isInvalidRow(item) {
            return (
                this.isInvalidRowContent(item) || item.status === ImportTableLabelTextHelper.getImportResultValue.error
            );
        },
        isInvalidRowContent(item) {
            return (
                item.shipmentNumber === SharedMsgHelper.getMissingRequiredErrMsg ||
                item.shipmentNumber === SharedMsgHelper.getNotAlphanumericErrMsg
            );
        },
        async update(idx) {
            const csvData = this.data.mappedCsv[idx];
            const response = await this.updateStatus(csvData);
            this.processResponse(response, idx, csvData);
        },
        async updateStatus(csvData) {
            const request = _.pick(csvData, ['shipmentNumber']);
            const requestUrl = `/api/customer/shipment/updateStatusReadyToShip`;
            return await fetchWrapper.post(requestUrl, request);
        },
        processResponse(response, idx, csvData) {
            if (response.success) {
                csvData.status = ImportTableLabelTextHelper.getImportResultValue.success;
            } else {
                csvData.status = ImportTableLabelTextHelper.getImportResultValue.error;
                csvData.error = response.errors;
            }
            this.data.mappedCsv.splice(idx, 1, csvData);
        }
    }
});
</script>

<style>
select {
    border: 1px solid !important;
}

.row-validation-error {
    background: red;
}
</style>
